import React, { useEffect, useRef } from "react";
import "./style.scss";
import ProjectCard from "../ProjectCard";
import projectsData from "../../data/marginData.json";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Projects() {

    const imgRef = useRef(null);
    const textH1Ref = useRef(null);
    const infoRef = useRef(null);
    const projRef = useRef(null);

    const gsapAnimation = (ref, deg) => {
        gsap.fromTo(ref.current,
            {
                y: "10rem",
                opacity: 0.5,
                rotate: `${deg}deg`
            },
            {
                y: "0rem", opacity: 1, rotate: "0deg", duration: 1, scrollTrigger: {
                    trigger: ref.current,
                    toggleActions: 'restart none restart none',
                    start: `-600px center`,
                    end: `top center`,
                    scrub: 2,
                    markers: false,
                }
            })
    }

    useEffect(() => {
        gsapAnimation(imgRef, -90);
        gsapAnimation(textH1Ref, 0);
        gsapAnimation(infoRef, 0);
    }, [])

    return (
        <section className="project-margin" id="project">
            <div className="intro">
                {/* <img src="./img/logo_margin.webp" alt="logo Margin Web Solution" className="logo" ref={imgRef} /> */}
                <div className="text">
                    <h2 ref={textH1Ref}>Projets</h2>
                    <p ref={infoRef}>
                        Au printemps 2024, je décide de m'associer à Marc, un ami développeur freelance. <br />Pour plus d'efficacité et par <span>amour du travail en équipe</span>, 
                        nous décidons de lancer <span>Margin Web Solutions</span>, notre micro-agence de création de sites vitrines <span>professionnels et performants</span>.
                        <br />
                        <br />
                        Ne soyez pas timide et <span>venez jeter un oeil</span> : <a className="link-margin" href="https://margin-web.com" target="_blank" rel="noopener noreferrer">margin-web.com</a>
                        <br />
                        <br />
                        Je vous invite également à découvrir deux de nos dernières réalisations <span>⬇</span>
                    </p>
                </div>
            </div>
            <div className="projects" ref={projRef}>
                {projectsData.map((item, index) => (
                    <ProjectCard
                        key={index}
                        img={item.img}
                        alt={item.title_des}
                        title={item.title}
                        title_des={item.title_des}
                        description={item.description}
                        url={item.url}
                        className="project"
                        skills={item.skills}
                    />
                ))}
            </div>
        </section>
    );
}